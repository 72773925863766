<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <Icon name="Send"></Icon>
        ساعت های گروه :
      </h2>
      <ul class="list-disc list-inside mt-3">
        <li class="text-red-500">
          ثبت سفارش تبلیغات به معنی قبول شرایط و
          <router-link to="/" class="text-sky-600">قوانین سفارش</router-link>
          می‌باشد
        </li>
        <li>
          برای خواندن شرایت و نحوه تبلیغات
          <router-link to="/" class="text-sky-600">اینجا کلیک کنید</router-link>
        </li>
        <li>بعد از تکمیل سفارش و پرداخت میتوانید بنر و کلید ها را مشخص کنید</li>
      </ul>
    </template>
    <template #default>
      <Button
        class="mb-5"
        :to="{
          name: 'TargetOrderSelect',
          params: { id: $store.state.user.id, adId: $route.params.adId },
        }"
      >
        مشاهده سایر گروه ها
      </Button>
      <div>
        <div
          class="
            bg-blueGray-100
            border border-blueGray-200
            rounded-md
            mb-5
            flex flex-wrap
            overflow-hidden
          "
        >
          <div
            class="
              w-full
              sm:w-5/12
              md:w-4/12
              border-b
              sm:border-l sm:border-b-0
              border-blueGray-200
              p-5
            "
          >
            <h2 class="text-md font-bold text-center">{{ group.name }}</h2>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ $number.format(group.members) }}</div>
              <div class="text-left">ممبر</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ $number.format(group.info?.ad_price) }}</div>
              <div class="text-left">تومان</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ list_gender[group.info?.gender] ?? "هردو" }}</div>
              <div class="text-left">جنسیت</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ group.info?.country?.fa_name || "-" }}</div>
              <div class="text-left">کشور</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ group.info?.province?.name || "-" }}</div>
              <div class="text-left">استان</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ group.info?.city?.name || "-" }}</div>
              <div class="text-left">شهر</div>
            </div>
          </div>
          <div class="w-auto p-5">
            <h2 class="text-md font-bold">توضیحات</h2>
            <p class="pt-3" v-if="group.info?.bio">{{ group.info?.bio }}</p>
            <p class="pt-3" v-if="group.info?.info">{{ group.info?.info }}</p>
          </div>
        </div>
        <div
          class="
            bg-blueGray-100
            border border-blueGray-200
            rounded-md
            my-5
            overflow-hidden
            grid grid-cols-2
            sm:grid-cols-4
            lg:grid-cols-6
            auto-rows-max
            gap-2
            p-3
          "
        >
          <TargetTime
            v-for="time in group.times"
            :key="group.id + '_' + time.en"
            :time="time"
            :send="L_.find(this.sends?.[group.id], ['send_at.def', time.en]) ?? {}"
            :groupId="group.id"
            @changeTime="setSends($event)"
          />
        </div>
      </div>
    </template>
    <template #bottom>
      <div class="grid grid-cols-2 sm:grid-cols-4 gap-1 md:gap-4 w-full">
        <div class="text-center flex justify-center items-center">
          {{ Object.values(this.sends).length }} گروه
        </div>
        <div class="text-center flex justify-center items-center">
          {{ L_.flatten(Object.values(this.sends)).length }} ارسال
        </div>
        <div class="text-center flex justify-center items-center">
          {{ $number.format(L_.sumBy(L_.flatten(Object.values(this.sends)), "price")) }}
          تومان
        </div>
        <div class="text-center flex justify-center items-center">
          <Button
            :to="{
              name: 'TargetOrderSelected',
              params: { id: $store.state.user.id, adId: $route.params.adId },
            }"
            class="text-sm h-10 lg:h-12"
            color="green"
          >
            تکمیل سفارش
          </Button>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";
import _ from "lodash";
import TargetTime from "@/components/TargetTime.vue";

export default {
  components: {
    Icon,
    Button,
    BodySimple,
    TargetTime,
  },
  data() {
    return {
      firstLoading: true,
      errors: [],
      gender: {
        value: 0,
        options: [
          {
            text: "آقا و خانم",
            value: 0,
          },
          {
            text: "فقط آقا",
            value: "male",
          },
          {
            text: "فقط خانم",
            value: "female",
          },
        ],
      },
      list_gender: {
        male: "آقا",
        female: "خانم",
      },
      search: "",
      showPro: false,
      searching: false,
      group: {},
      sends: [],
      groupsCount: 0,
      L_: _,
    };
  },
  mounted() {
    let $this = this;

    this.$router
      .isReady()
      .then(function () {
        $this.getGroups();
        // $this.getAd();
      })
      .catch();
  },
  methods: {
    getGroups() {
      let $this = this;
      $this.$axios
        .get("api/ad-targets-select/" + $this.$route.params.adId + "/group", {
          params: {
            id: $this.$store.state.user.id,
            groupId: $this.$route.params.groupId,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          $this.setSends(response.data.ad);
          // $this.groupsCount = response.data.all;
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.searching = false;
          $this.firstLoading = false;
          $this.disabaledForm = false;
        });
    },
    setSends(ad) {
      this.sends = _.groupBy(ad.sends, "group_id");
    },
  },
};
</script>
